// 
// _nav.scss
// 

.nav-tabs,.nav-pills {
  > li {
      > a {
          color: $gray-700;
          font-weight: $font-weight-medium;
      }
  }
}

.nav-pills {
  > a {
      color: $gray-700;  
      font-weight: $font-weight-medium;
  }
}


.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      background-color: #ededed36;
      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active{
        color: $primary;
        // TODO
        background: #fff;
        padding: 0.5rem 2.5rem;
        box-shadow: 1px 5px 20px 0px #d4d4d459;
        &:after{
          transform: scale(1);
        }
        span{
          font-weight: 600;
        }
      }

    }
  }
}


// vertical nav

.vertical-nav{
  .nav{
      .nav-link{
          padding: 24px 16px;
          text-align: center;
          margin-bottom: 8px;
          .nav-icon{
              font-size: 24px;
          }
      }
  }
}